<section class="activity-section">
  <!-- <img class="web-img2" src="../../../assets/images/halloween-wheel/web5.png" alt=""> -->

  <h1 class="dialog-title">Wuuhuuu!</h1>

  <app-halloween-product-img style="display:flex; justify-content: center; object-fit: cover; min-height: 200px; width: 100%;"
    [url]="activity.imageStr" [text]="activity.name"></app-halloween-product-img>
    <img class="web2-img" src="../../../assets/images/halloween-wheel/web5.png" alt="">


  <!-- Product description -->
  @if (activity.description) {
    <p class="desc-text" [innerHTML]="activity.description"></p>
  }

  <!-- Toggle arrow -->
  <br>
  <mat-card-footer style="text-align: center;" (click)="toggleDisplayDiv()">
    <div class="rotate" [class.down]="classApplied">
      <button mat-icon-button color="accent" aria-label="Icon-button with arrow back icon">
        <fa-icon [icon]="faChevronDown" size="lg" style="color: black;"></fa-icon>
      </button>
    </div>
  </mat-card-footer>

  @if (classApplied) {

    <!-- Materials -->
    @if (activity.materials && activity.materials.length > 0) {
      <div mat-subheader style="font-family: 'Creepster';"><strong>{{ 'product-activity.you-will-need' | translate }}</strong></div>
      <ul>
        @for (material of activity.materials; track material) {
          <li class="list-styling" [innerHTML]="material"></li>
        }
      </ul>
      <br>
    }

    <!-- Steps -->
    @if (activity.steps && activity.steps.length > 0) {
      <div>
        @for (step of activity.steps; track step) {
          <p style="font-size: 16px; line-height: 1.5;" [innerHTML]="step"></p>
        }
      </div>
    }

    <!-- Download -->
    @if (activity.fileStr) {
      <a [href]="activity.fileStr" target="_blank" rel="noopener noreferrer">
        {{ activity.fileStr | fileName }}
      </a>
    }

    <!-- Tip -->
    @if (activity.tip) {
      <section class="tip-box">
        <p
          style="margin: 0; padding: 0; padding-top: 10px; color: #242424; font-size: var(--adult-body); font-weight: bold; line-height: 1.5; font-family: 'Creepster';">
          {{ 'product-activity.tip' | translate
          }}</p>
        <p style="color: #242424; font-size: var(--adult-body); line-height: 1.5;">{{ activity.tip }}</p>
      </section>
    }
  }
  <!-- <img class="web-img" src="../../../assets/images/halloween-wheel/web3.png" alt=""> -->
</section>