import { HttpClient } from "@angular/common/http";
import { computed, Injectable } from "@angular/core";
import { BehaviorSubject, of } from "rxjs";
import { catchError, map, shareReplay, switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ResultArray } from "../result-array.data";
import { toSignal } from "@angular/core/rxjs-interop";
import { HttpErrorService } from "../error/http-error.service";
import { WheelOfActivities } from "./wheel-of-activities.data";

@Injectable({
  providedIn: 'root'
})
export class WheelOfActivitiesService {
  private wheelsUrl = `${environment.apiUri}/v1.0/wheelsofactivities`;
  private wheelModifiedSubject = new BehaviorSubject<void>(undefined);

  private wheelsResult$ = this.wheelModifiedSubject.pipe(
    switchMap(() => {
      return this.http.get<WheelOfActivities[]>(this.wheelsUrl)
    }),
    map(wheels => ({ data: wheels } as ResultArray<WheelOfActivities[]>)),
    shareReplay(1),
    catchError(err => {
      console.error(this.httpErrorService.formatError(err));
      return of({ data: [], error: this.httpErrorService.formatError(err) } as ResultArray<WheelOfActivities[]>);
    })
  );
  private wheelsResult = toSignal(
    this.wheelsResult$,
    { initialValue: ({ data: [] } as ResultArray<WheelOfActivities[]>) }
  );
  wheels = computed(() => this.wheelsResult().data);
  wheelsError = computed(() => this.wheelsResult().error);

  constructor(
    private http: HttpClient,
    private httpErrorService: HttpErrorService
  ) { }
}
