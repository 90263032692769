import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-halloween-wheel-info-dialog',
  templateUrl: './halloween-wheel-info-dialog.component.html',
  styleUrl: './halloween-wheel-info-dialog.component.scss'
})
export class HalloweenWheelInfoDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<HalloweenWheelInfoDialogComponent>
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
