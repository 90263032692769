import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Activity } from 'src/app/core/services/catalog/activity.data';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { EarlierWonActivityComponent } from '../earlier-won-activity/earlier-won-activity.component';
import { WonActivity } from 'src/app/core/services/won-activity/won-activity.data';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-earlier-won-activities',
  templateUrl: './earlier-won-activities.component.html',
  styleUrl: './earlier-won-activities.component.scss'
})
export class EarlierWonActivitiesComponent {
  boo: AnimationOptions = {
    path: '/assets/lottie/boo.json',
  };

  faChevronRight = faChevronRight;

  constructor(
    public dialogRef: MatDialogRef<EarlierWonActivitiesComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public wonActivities: WonActivity[]
  ) { }

  closeDialog()
  {
    this.dialogRef.close();
  }

  navigateEarlyActivity(wonActivity: WonActivity) {
    this.dialog.open(EarlierWonActivityComponent, {
      data: wonActivity.activity,
      panelClass: 'halloween-activity-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });
  }
}
