<!-- Background image -->
<div class="dialog-wrapper">

  <div class="backbtn-wrapper">
    <app-halloween-back-btn (navigateBackEvent)="closeDialog()"></app-halloween-back-btn>
  </div>

  @if (wonActivitiesError() || wheelsError() || catalogActivitiesError()) {
    <app-error-img></app-error-img>
  }

  @if (activities().length > 0) {
    <div>
      <h1 class="title-text">{{ 'halloween-wheel.title' | translate }}</h1>

      <!-- Wheel of halloween -->

      <!-- <app-wheel-of-halloween [activities]="activities()" [canSpin]="todaysActivity() !== undefined" -->
        <app-wheel-of-halloween [activities]="activities()" [canSpin]="!todaysActivity()"
        (wheelSpunEvent)="onWheelSpun($event)" (rotateEvent)="listenForWheelRotation()"></app-wheel-of-halloween>

      <p class="title"> {{ 'halloween-wheel.daily-activity' |  translate }}</p>

      <div style="display: flex; justify-content: end; width: 100%; max-width: 100%;">
        <ng-lottie [options]="coffin" width="200px" height="auto" style="z-index: 100;" />
      </div>
    
      <img class="info-img" src="../../../assets/images/halloween-wheel/info-icon.png" (click)="openInfoIcon()">
      <div style=" padding-left:24px; padding-right:24px;">
      </div>
    </div>
  }
