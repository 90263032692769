<!-- Background image -->
<div class="dialog-wrapper">
    <div class="backbtn-wrapper">
        <app-halloween-back-btn (navigateBackEvent)="closeDialog()"></app-halloween-back-btn>
    </div>

    <section class="middle-section">
        <img class="happy-halloween-img" src="../../../assets/images/halloween-wheel/happy-halloween.png" alt=""
            width="300px" height="auto">


        <ng-lottie [options]="boo" width="300px" height="50px"
            style="display: flex; justify-content: end; width: 100%; max-width: 100%; margin-right: -100px;" />

        <div class="btn-and-lottie" style="position: relative;">
            <ng-lottie [options]="spider" width="70px" height="200px"
                style="position: absolute; top: -100px; left: 0; z-index: 100;" />

            @if(todaysActivity()) {
                <button style="height: 50px; width: 100px; border-radius: 30px;" class="halloween-btn-grey"
                >{{ 'halloween-campaign.spin' | translate
                }} <img class="spider-web" src="../../../../assets/images/halloween-wheel/web.png" width="80px"
                    height="auto" alt=""></button>
            }
            @else {

                    <button style="height: 50px; width: 100px; border-radius: 30px;" class="halloween-btn"
                    (click)="navigateHalloweenWheel()">{{ 'halloween-campaign.spin' | translate
                    }} <img class="spider-web" src="../../../../assets/images/halloween-wheel/web.png" width="80px"
                        height="auto" alt=""></button> 
            }
        </div>

        @if(todaysActivity()) {
        <div style="display: flex; justify-content: end; width: 100%; max-width: 100%; margin-top: 40px;">
            <ng-lottie [options]="coffin" width="150px" height="auto" style="z-index: 100;" (click)="navigateEarlierActivities('600ms', '500ms')"/>
          </div>
        }

          <!-- <ng-lottie [options]="candle" width="300px" height="70px" style="position: absolute; bottom: 0; right:0; z-index: 200;" />     -->

    </section>
    <img class="info-img" src="../../../assets/images/halloween-wheel/info-icon.png" (click)="openInfoIcon()">
</div>