import { AfterViewInit, Component, computed } from '@angular/core';
import { HalloweenWheelPageComponent } from '../halloween-wheel-page/halloween-wheel-page.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { HalloweenWheelInfoDialogComponent } from '../halloween-wheel-info-dialog/halloween-wheel-info-dialog.component';
import { Router } from '@angular/router';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { AnimationOptions } from 'ngx-lottie';
import { EarlierWonActivitiesComponent } from '../earlier-won-activities/earlier-won-activities.component';
import { TodayService } from 'src/app/core/services/today/today.service';
import { TicksService } from 'src/app/core/services/ticks/ticks.service';
import { WonActivityService } from 'src/app/core/services/won-activity/won-activity.service';
import { Activity } from 'src/app/core/services/catalog/activity.data';

@Component({
  selector: 'app-halloween-greeting-dialog',
  templateUrl: './halloween-greeting-dialog.component.html',
  styleUrl: './halloween-greeting-dialog.component.scss'
})
export class HalloweenGreetingDialogComponent implements AfterViewInit {
  boo: AnimationOptions = {
    path: '/assets/lottie/boo.json',
  };

  candle: AnimationOptions = {
    path: '/assets/lottie/candle.json',
  };

  spider: AnimationOptions = {
    path: '/assets/lottie/spider.json',
  };

  coffin: AnimationOptions = {
    path: '/assets/lottie/coffin2.json',
  };

  /* Get audio ready for use in different functions */
  audioBackground = new Audio();

  faChevronRight = faChevronRight;

  todaysActivity = computed(() => {
    return this.wonActivityService.activities().find(a => this.todayService.isToday(this.ticksService.getDate(a.created)));
    // return undefined;
  });

  ngAfterViewInit() {
    /* ----------- Start audio when page loads ------------ */
    setTimeout(() => {
      this.audioBackground.src = "../../../../assets/sounds/halloween.mp3";
      this.audioBackground.load();
      this.audioBackground.volume = 0.3;
      this.audioBackground.muted = false; // without this line it's not working 
      this.audioBackground.play()
    }, 50);
  }

  ngOnDestroy(): void {
    this.audioBackground.loop = false;
    this.audioBackground.pause();
    this.audioBackground.currentTime = 0;
  }

  constructor(
    public dialog: MatDialog,
    private location: Location,
    private router: Router,
    public dialogRef: MatDialogRef<HalloweenGreetingDialogComponent>,
    private todayService: TodayService,
    private ticksService: TicksService,
    private wonActivityService: WonActivityService
  ) {}

  navigateBack(): void {
    this.audioBackground.pause();
    this.audioBackground.currentTime = 0;
    this.location.back();
  }

  openInfoIcon(): void {
    const dialogRef = this.dialog.open(HalloweenWheelInfoDialogComponent, {
      panelClass: 'halloween-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.router.navigateByUrl(result);
      }
    });
  }

  closeDialog(): void {
    this.audioBackground.pause();
    this.audioBackground.currentTime = 0;
    this.dialogRef.close();
  }

  navigateHalloweenWheel() {
    this.dialog.open(HalloweenWheelPageComponent, {
      data: this.wonActivityService.activities(),
      panelClass: 'halloween-wheel-dialog',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      autoFocus: false
    });
  }

  navigateEarlierActivities(enterAnimationDuration: string, exitAnimationDuration: string): void {
    let wonActivities = this.wonActivityService.activities().sort((a,b) => a.created - b.created);
    wonActivities.forEach(wonActivity => {
      const wonActivityObj = JSON.parse(wonActivity.activityStr);
      wonActivity.activity = wonActivityObj as Activity;
    });

    this.dialog.open(EarlierWonActivitiesComponent, {
      data: wonActivities,
      panelClass: 'halloween-dialog-early',
      height: '100%',
      width: '100%',
      maxWidth: '480px',
      enterAnimationDuration,
      exitAnimationDuration,
      autoFocus: false
    });
  }
}
