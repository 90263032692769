<div class="dialog-wrapper">
  <img class="web-img2" src="../../../assets/images/halloween-wheel/web5.png" alt="">

  <div class="backbtn-wrapper">
    <section class="btn-wrapper">
      <div class="back-btn" (click)="closeDialog()">
        <fa-icon [icon]="faArrowLeft" class="arrow-icon" size="xl" style="color: #fff;"></fa-icon>
      </div>
    </section>
  </div>

  <h1>{{ activity.name }}</h1>

  <app-halloween-product-img style="display:flex; justify-content: center; object-fit: cover; min-height: 200px; width: 100%;"
  [url]="activity.imageStr" [text]="activity.name"></app-halloween-product-img>
    <div style="position: relative; margin-bottom: 15px;">
  <img class="web2-img" src="../../../assets/images/halloween-wheel/web2.png" alt=""></div>

  <br>
  <p
    style="display:flex; justify-content: center; flex-wrap: wrap; margin-bottom: 24px; line-height: 1.5; font-size: 16px;"
    [innerHTML]="activity.description"
  >
  </p>
  <br>

  <!-- Materials -->
  @if (activity.materials && activity.materials.length > 0) {

    <div style="color: #000; font-size: 20px; line-height: 1.5;font-family: 'Creepster', system-ui; letter-spacing: 1.5px;">
      {{ 'product-activity.you-will-need' | translate }}
    </div>

    <ul>
      @for (material of activity.materials; track material) {
        <li class="list-styling" [innerHTML]="material"></li>
      }
    </ul>
    <br>
  }
  <br>

  <!-- Steps -->
  @if (activity.steps && activity.steps.length > 0) {
    <div>
      @for (step of activity.steps; track step) {
        <p style="color: #242424; font-size: 16px; line-height: 1.5;" [innerHTML]="step"></p>
      }
    </div>
  }

  <!-- Download -->
  @if (activity.fileStr) {
    <a [href]="activity.fileStr" target="_blank" rel="noopener noreferrer">
      {{ activity.fileStr | fileName }}
    </a>
  }

  <!-- Tip -->
  @if (activity.tip) {
    <br>
    <br>
    <section class="tip-box">
      <p style="margin: 0; padding: 0; padding-top: 10px; color: #000; font-size: 20px; line-height: 1.5;font-family: 'Creepster', system-ui; letter-spacing: 1.5px;">
        {{ 'product-activity.tip' | translate }}
      </p>
      <p style="color: #242424; font-size: 16px; line-height: 1.5;">{{ activity.tip }}</p>
    </section>
  }

  <img class="web-img" src="../../../assets/images/halloween-wheel/web3.png" alt="">
</div>