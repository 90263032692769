import { Component, EventEmitter, Output } from '@angular/core';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-halloween-back-btn',
  templateUrl: './halloween-back-btn.component.html',
  styleUrl: './halloween-back-btn.component.scss'
})
export class HalloweenBackBtnComponent {
  @Output() navigateBackEvent = new EventEmitter();

  faArrowLeft = faArrowLeft;

  navigateBack() {
    this.navigateBackEvent.emit();
  }
}
