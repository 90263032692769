import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Activity } from 'src/app/core/services/catalog/activity.data';
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

@Component({
  selector: 'app-won-halloween-activity-dialog',
  templateUrl: './won-halloween-activity-dialog.component.html',
  styleUrl: './won-halloween-activity-dialog.component.scss'
})
export class WonHalloweenActivityDialogComponent {

  faChevronDown = faChevronDown;

  classApplied = false;
  isShowDiv  = true;

  showMore: boolean = false;

toggleReadMore() {
  this.showMore = !this.showMore;
}

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
    this.classApplied = !this.classApplied;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public activity: Activity
  ) { }
}
