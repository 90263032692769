import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-halloween-product-img',
  templateUrl: './halloween-product-img.component.html',
  styleUrl: './halloween-product-img.component.scss'
})
export class HalloweenProductImgComponent {
  @Input() url: string | null = '';
  @Input() text: string = '';
}
