<!-- <div class="dialog-wrapper">

  <div class="backbtn-wrapper">
    <app-halloween-back-btn (navigateBackEvent)="closeDialog()"></app-halloween-back-btn>
</div> -->

  <section class="coffin-wrapper">

    <ng-lottie [options]="boo" width="300px" height="60px" style="margin-right: -100px;"/>

    <div class="coffin-top">
      <h1>{{ 'halloween-wheel.earlier-won' | translate }}</h1>
    </div>

    <div class="coffin-middle">
      <section class="activity-section">
        <ul style="display: flex; flex-direction: column; gap: 1rem; padding: 0 8px;">

          @for (wonActivity of wonActivities; track wonActivity.id) {
            <li>
              <button class="won-activity-btn" (click)="navigateEarlyActivity(wonActivity)">
                <img class="reward-img" [src]="wonActivity.activity.imageStr">
                <div class="arrow-wrapper">
                  <fa-icon [icon]="faChevronRight" style="color: #fff; font-size: 40px;"></fa-icon>
                </div>
              </button>
            </li>
          }
        </ul>
      </section>
    </div>

    <div class="coffin-btm">
       <img class="close-img" src="../../../assets/images/halloween-wheel/close.png" width="80px" height="auto" alt="" (click)="closeDialog()"> 
    </div>
  </section>
<!-- </div> -->