<div class="dialog-wrapper">

    <h1>{{ 'halloween-wheel.info' | translate }}</h1>

    <section class="info-card">
        <h2> {{ 'halloween-wheel.info-title' | translate }}</h2>

        <h3>{{ 'halloween-wheel.info-subtitle' | translate }}</h3>

        <p>{{ 'halloween-wheel.info-text1' | translate }}
            <br><br>
            {{ 'halloween-wheel.info-text2' | translate }}
            <br><br>
            {{ 'halloween-wheel.info-text3' | translate }}
        </p>

        <div class="circle">
            <label class="obs-styling">OBS!</label>
        </div>

        <h3>{{ 'halloween-wheel.info-subtitle2' | translate }}</h3>
        <ul>
            <li>{{ 'halloween-wheel.info-text4' | translate }}</li>
            <li>{{ 'halloween-wheel.info-text5' | translate }}</li>
        </ul>
    </section>

    <div class="backbtn-wrapper">
        <app-halloween-back-btn (navigateBackEvent)="closeDialog()"></app-halloween-back-btn>
    </div>
</div>